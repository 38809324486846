/* ---- Imports ---- */

import { createRouter, createWebHistory } from 'vue-router'
import { helperServiceWorker } from '../helpers/index'

import app from './app'

const router = createRouter({
  history: createWebHistory(),
  routes: [...app]
})

router.afterEach((to) => {
  document.title = to.meta.title
})

router.beforeEach((_to, _from, next) => {
  helperServiceWorker.ativar()
  next()
})

export default router
