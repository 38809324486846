<template>
  <Alerta />
  <div class="views">
    <component :is="layout" />
  </div>
</template>

<script setup>
import { computed, onMounted, watch, inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStoreAreas, useStoreAlerta, useStorePerfil } from '@stores'
import Alerta from '@components/global/alerta/Alerta.vue'

const { currentRoute } = useRouter()
const route = useRoute()
const storeAreas = useStoreAreas()
const storeAlerta = useStoreAlerta()
const storePerfil = useStorePerfil()
const emitter = inject('emitter')

const layout = computed(() => currentRoute?.value?.meta.layout || 'default-layout')

async function carregarPerfil() {
  if (route.meta.logged) {
    try {
      await storePerfil.receberPorId()
    } catch (error) {
      console.error('Erro ao carregar perfil:', error)
    }
  }
}

async function carregarArea() {
  const dominio = window.location.hostname

  if (dominio.includes('localhost') || dominio.includes('mber.live')) {
    const hotlink = route.params.hotlink
    if (hotlink) {
      await storeAreas.receberPorHotlink(hotlink)
    }
  } else {
    await storeAreas.receberPorDominioPersonalizado(dominio)
  }

  document.title = storeAreas.area?.nome || 'Level Member'
  prepararMetatags()
}

function mostrarAlerta(payload) {
  storeAlerta.mostrarAlerta({
    visivel: true,
    mensagem: payload.mensagem,
    classe: payload.tipo
  })
}

function prepararMetatags() {
  const favicon = storeAreas.area?.metatags?.favicon || ''
  const faviconIco = favicon.includes('.ico') ? favicon : ''
  const faviconPng = favicon.includes('.png') ? favicon : ''

  renderizarMetatags(faviconIco, faviconPng)
}

function renderizarMetatags(faviconIco, faviconPng) {
  if (faviconIco) document.querySelector('link[rel="shortcut icon"]')?.setAttribute('href', faviconIco)
  if (faviconPng) {
    const sizes = ['32x32', '192x192', '16x16']
    sizes.forEach((size) => {
      document.querySelector(`link[rel="icon"][sizes="${size}"]`)?.setAttribute('href', faviconPng)
    })
    document.querySelector('link[rel="apple-touch-icon"]')?.setAttribute('href', faviconPng)
  }

  const urlCanonical = window.location.origin + window.location.pathname
  updateLinkHref('link[rel="canonical"]', urlCanonical)
  updateMetaContent('meta[property="og:url"]', urlCanonical)
}

function updateMetaContent(selector, content) {
  document.querySelector(selector)?.setAttribute('content', content)
}

function updateLinkHref(selector, href) {
  document.querySelector(selector)?.setAttribute('href', href)
}

onMounted(() => {
  emitter.on('mostrarAlerta', mostrarAlerta)
  emitter.on('atualizarPerfil', carregarPerfil)
  carregarPerfil()
})

watch(
  () => route.params.hotlink,
  async (newHotlink, oldHotlink) => {
    if (newHotlink !== oldHotlink || newHotlink) {
      await carregarArea()
    }
  },
  { immediate: true }
)

watch(
  () => window.location.hostname,
  async (newDomain, oldDomain) => {
    if (newDomain !== oldDomain || newDomain) {
      await carregarArea()
    }
  },
  { immediate: true }
)

watch(
  () => route.path,
  (newPath, oldPath) => {
    if (newPath !== oldPath) {
      carregarPerfil()
    }
  }
)
</script>
